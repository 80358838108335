import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { GetStaticProps, GetStaticPaths, InferGetStaticPropsType } from 'next';
import Head from '@components/Head';
import Layout from '@components/Layout';
import getUrlByEnv from '@utils/get-url-by-env';
import getNav from '@framework/api/operations/get-nav';
import getCollection from '@framework/api/operations/get-collection';
import getAllCollectionsByType from '@framework/api/operations/get-all-collections-by-type';
import { getRegion } from '@framework/api/utils/maxify';
import decorateCollectionContent from '@utils/decorate-collection-content';
import { STAGING, ROUTE_COLLECTION, REVALIDATE_VALUES } from '@constants';
import CollectionPageComponent from '@components/CollectionPage/CollectionPage';

const revalidate = +process.env.NEXT_PUBLIC_PRODUCT_REVALIDATION_TIME || 30;

export const getStaticProps: GetStaticProps = async ({
  locale,
  params,
  preview,
}) => {
  const envName =
    process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
      ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
      : process.env.NEXT_PUBLIC_ENV_NAME;

  const baseUrl = getUrlByEnv(envName);

  const slug = Array.isArray(params.slug) ? params.slug[0] : params.slug;

  const [data, nav] = await Promise.all([
    getCollection({ slug, locale, preview }),
    getNav({ slug: 'main', locale, preview }),
  ]);

  if (!data) {
    if (!slug.endsWith('.css.map')) {
      Sentry.captureException(
        new Error(`Collection with slug '${slug}' not found`)
      );
    }

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  const { content, ...rest } = data;
  const decoratedCollectionContent = await decorateCollectionContent(
    content,
    locale
  );
  const newData = { content: decoratedCollectionContent, ...rest };

  if (!newData?.slug) {
    Sentry.captureException(
      new Error(`Dato error with collection slug '${slug}' in ${locale}`)
    );

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.fallback,
    };
  }

  return {
    props: {
      preview: preview || false,
      baseUrl,
      data: newData,
      nav,
    },
    revalidate,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const allCollections = await getAllCollectionsByType('collection');

  return {
    paths:
      allCollections?.map(
        collection => `/${ROUTE_COLLECTION}/${collection.slug}`
      ) || [],
    fallback: 'blocking',
  };
};

const CollectionPage: InferGetStaticPropsType<typeof getStaticProps> = ({
  baseUrl,
  data,
}) => {
  const router = useRouter();
  const { query, locale } = router;
  const region = getRegion(locale);

  return (
    <>
      <Head
        baseUrl={baseUrl}
        canonical={`${baseUrl}/${ROUTE_COLLECTION}/${query.slug}`}
        cmsData={data.seo}
      />
      <CollectionPageComponent data={data} region={region} />
    </>
  );
};

CollectionPage.Layout = Layout;

export default CollectionPage;
