/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import type { DatoCollection } from '@framework/api/types';
import PageContent from '@components/Content/PageContent';

type Props = {
  data: DatoCollection;
  region: string;
};

const CollectionPage: FC<Props> = ({ data, region }) => (
  <PageContent data={data} region={region} />
);

export default memo(CollectionPage);
